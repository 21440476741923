import React from 'react';
import { useApproachPageEntry } from '@hooks/cms';
import { EntryContextProvider } from '@context/EntryContext';
import Layout from '@c/Layout';
import Hero from '@c/Hero';
import ArticleContent from '@c/ArticleContent';

const ApproachPage = () => {
  const approachPageCmsEntry = useApproachPageEntry(),
    approachPageEntry = approachPageCmsEntry.entry,
    seomatic = approachPageCmsEntry.seomatic;

  return (
    <EntryContextProvider entry={approachPageEntry}>
      <Layout seomatic={seomatic}>
        {!!approachPageEntry.hero && !!approachPageEntry.hero.length && (
          <Hero {...approachPageEntry.hero[0]} />
        )}

        <ArticleContent
          spacing={{ ml: { xs: 0, md: 8 } }}
          content={approachPageEntry.articleContent}
          mb={{ xs: 12.5, md: 20 }}
        />
      </Layout>
    </EntryContextProvider>
  );
};

export default ApproachPage;
